import React from 'react';

const NavigatePostedEvents = () => {

    return (
        <div>
            <div className="my-app-section pb-0">
                <div className='customize-website-nav'>
                    <h6 className='customize-website-nav-title'>Posted Media</h6>
                    <div className='customize-website-nav-links'>
                        <a href="/posted-media/event" className={`btn cus-web-nav-link ${window.location.pathname === '/posted-media/event' ? 'active' : ''}`}><i class="bi bi-calendar2-event-fill"></i>Event</a>
                        <a href="/posted-media/blog" className={`btn cus-web-nav-link ${window.location.pathname === '/posted-media/blog' ? 'active' : ''}`}><i class="bi bi-substack"></i>Blog</a>
                        <a href="/posted-media/video" className={`btn cus-web-nav-link ${window.location.pathname === '/posted-media/video' ? 'active' : ''}`}><i class="bi bi-camera-video-fill"></i>Video</a>
                        <a href="/posted-media/podcast" className={`btn cus-web-nav-link ${window.location.pathname === '/posted-media/podcast' ? 'active' : ''}`}><i class="bi bi-mic-fill"></i>Podcast</a>
                        <a href="/posted-media/news" className={`btn cus-web-nav-link ${window.location.pathname === '/posted-media/news' ? 'active' : ''}`}><i class="bi bi-newspaper"></i>News</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavigatePostedEvents