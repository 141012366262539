import React, { useState, useEffect, useContext } from 'react';
import ATSLayout from '../../components/ATSLayout';
import Footer from '../../components/Footer';
import './Testimonials.css';
import NavigateTestimonials from './NavigateTestimonials';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import TagManager from 'react-gtm-module'

const TestimonialsText = () => {
    const staffToken = JSON.parse(localStorage.getItem('staffToken'))
    const { clearTestimonial, CreateOrUpdateTestimonial, allTestimonials, getAllTestimonials, loadingTest, deleteTestimonial, hideOrShowTestimonial } = useContext(AuthContext);
    const [fileError, setFileError] = useState('');
    const [page, setPage] = useState(1);
    const [editStatus, setEditStatus] = useState(false);

    useEffect(() => {
        if (staffToken) {
            getAllTestimonials(page, 10, "All", "Text", staffToken);
        }
    }, [staffToken, page]);


    useEffect(() => {
        if (clearTestimonial) {
            // setTestimonial(testimonialText);
            // getAllTestimonials(1, 10, "All", "Text", staffToken);
            window.location.reload();
        }
    }, [clearTestimonial])


    //for show success message for payment
    function showSuccessMessage(message) {
        Swal.fire({
            title: 'Congratulations!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSize = 2 * 1024 * 1024;

        if (selectedFile && selectedFile.size > maxSize) {
            setFileError('File size exceeds 2MB limit');
            event.target.value = '';
        } else {
            setFileError('');
            setTestimonial({ ...testimonial, photoUrl: selectedFile });
        }
    };

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    const testimonialText = {
        name: "",
        position: "",
        photoUrl: null,
        content: "",
        viewType: "",
    }
    const [testimonial, setTestimonial] = useState(testimonialText);
    const [require, setRequire] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTestimonial({ ...testimonial, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setRequire(false);
        if (testimonial.name && testimonial.position && testimonial.content) {
            const formData = new FormData();
            formData.append("name", testimonial.name);
            formData.append("position", testimonial.position);
            formData.append("content", testimonial.content);
            formData.append("photoUrl", testimonial.photoUrl);
            formData.append("viewType", testimonial.viewType);
            formData.append("type", "Text");

            CreateOrUpdateTestimonial(formData, staffToken);

        } else {
            setRequire(true);
        }

    }

    const handleUpdate = (event, id) => {
        event.preventDefault();
        setRequire(false);
        if (testimonial.name && testimonial.position && testimonial.content) {
            const formData = new FormData();
            formData.append("name", testimonial.name);
            formData.append("position", testimonial.position);
            formData.append("content", testimonial.content);
            formData.append("photoUrl", testimonial.photoUrl);
            formData.append("viewType", testimonial.viewType);
            formData.append("type", "Text");
            formData.append("id", id);

            CreateOrUpdateTestimonial(formData, staffToken);

        } else {
            setRequire(true);
        }

    }

    const handleDeleteTestimonial = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete testimonial!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTestimonial(id, staffToken)
                    .then(() => {
                        if (allTestimonials?.data.length === 1) {
                            setPage(page - 1);
                        } else {
                            getAllTestimonials(page, 10, "All", "Text", staffToken);
                        }
                    })
                    .catch((error) => {
                        console.error("Error deleting testimonial:", error);
                    });
            }

        });
    }

    const handleChangeShowOrHide = async (id) => {
        await hideOrShowTestimonial(id, staffToken);
        await getAllTestimonials(page, 10, "All", "Text", staffToken);
    }

    useEffect(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            userProject: 'skillity-admin',
            page: `testimonial/text`
          }
        });
      }, []);

    return (
        <div>
            <div class="main-wrapper main-wrapper-1">
                <div class="navbar-bg"></div>
                <ATSLayout />
                <div class="main-content">
                    <section class="section">
                        <NavigateTestimonials />
                        <div className="my-app-section pt-0">
                            {loadingTest ? (
                                <div className="table-skeleton-area">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-data-skeleton-area">
                                                <div className="custom-flex-area">
                                                    <div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={25} width={250} />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={15} width={120} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-scroll-area mt-4 skeleton-table">
                                                    <div className="table skeleton-table table-striped table-hover admin-lg-table">
                                                        <tr className="skeleton-table-row">
                                                            <th className='w-5'>
                                                                <Skeleton height={18} width={30} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-20'>
                                                                <Skeleton height={18} width={80} />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="admin-lg-table-section">
                                            <div className='admin-lg-table-area man-app'>
                                                <div className='man-app-title-area custom-flex-area'>
                                                    <div>
                                                        <div className="man-app-title">
                                                            Testimonials
                                                        </div>
                                                        {allTestimonials && <div className="man-app-sub-title">
                                                            Total Records :&nbsp;
                                                            <span>{allTestimonials?.totalTestimonials}</span>
                                                        </div>}
                                                    </div>
                                                    <div className="create-btn-area">
                                                        <button
                                                            className='btn creat-data-btn'
                                                            data-toggle="modal"
                                                            title='Create new testimonial'
                                                            data-target="#testimonialCreateModal"
                                                            onClick={() => {
                                                                setRequire(false)
                                                                setTestimonial(testimonialText);
                                                                setEditStatus(false);
                                                            }}
                                                        >
                                                            <i class="bi bi-person-plus-fill"></i>
                                                            <span>Create New</span>
                                                        </button>
                                                    </div>
                                                </div>

                                                {/* <div className="no-data-created-area">
                                                    <div className='no-data-created'>
                                                        <img src="../assets/img/no-data/no-data-img.png" className='no-data-img' alt="" />
                                                        <div className='no-data-text'>No record found..!</div>
                                                    </div>
                                                </div> */}

                                                <div className="table-responsive table-scroll-area">
                                                    <table className="table table-striped table-hover admin-lg-table">
                                                        <tr className='dash-table-row man-app'>
                                                            <th className='dash-table-head'>No.</th>
                                                            <th className='dash-table-head'>Name</th>
                                                            <th className='dash-table-head'>Position</th>
                                                            <th className='dash-table-head'>Review</th>
                                                            <th className='dash-table-head text-left'>Action</th>
                                                        </tr>

                                                        {/* table data */}
                                                        {allTestimonials?.data.map((test, index) => {
                                                            return (
                                                                <tr className='dash-table-row client'
                                                                    key={test._id}>
                                                                    <td className='dash-table-data1'>{((index + 1) + ((page - 1) * 10))}.</td>
                                                                    <td className='dash-table-data1 text-capitalized'>
                                                                        {test.name}
                                                                    </td>
                                                                    <td className='dash-table-data1'>
                                                                        {test.position}
                                                                    </td>

                                                                    <td className='dash-table-data1'>
                                                                        {test.content}
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        <div className="action-btn-area">
                                                                            <button className='job-edit-btn' title='Edit testimonial data...' data-toggle="modal" data-target="#testimonialCreateModal"
                                                                                onClick={() => {
                                                                                    setTestimonial(test);
                                                                                    setEditStatus(true);
                                                                                }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                                </svg>
                                                                            </button>

                                                                            <button className='job-delete-btn' data-toggle="modal" title='Delete testimonial data...' data-target="#contactMsgdeleteModal"
                                                                                onClick={() => handleDeleteTestimonial(test._id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                                </svg>
                                                                            </button>

                                                                            <button className={test.status ? 'status-change-btn deactive' : 'status-change-btn active'} title={test.status ? 'Hide' : "Show"} onClick={() => handleChangeShowOrHide(test._id)}>
                                                                                {test.status ? "Hide" : "Show"}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                </div>
                                            </div>

                                            {allTestimonials && <div className="table-pagination-area pt-3">
                                                <div className="pagination-btn-area">
                                                    {(allTestimonials?.currentPage !== 1 && allTestimonials?.totalPages !== 1) && <button className='pag-prev-btn' onClick={() => setPage(page - 1)}>
                                                        <i class="bi bi-chevron-left"></i>
                                                    </button>}
                                                    <div className='pag-page'>
                                                        <span className='current-page'>{allTestimonials?.currentPage}</span>&nbsp;/&nbsp;
                                                        <span className='total-page'>{allTestimonials?.totalPages}</span>
                                                    </div>
                                                    {(allTestimonials?.currentPage !== allTestimonials?.totalPages) && <button className='pag-next-btn' onClick={() => setPage(page + 1)}>
                                                        <i class="bi bi-chevron-right"></i>
                                                    </button>}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>

                {/* Testimonial create modal here */}
                <div className="modal fade" id="testimonialCreateModal" tabindex="-1" role="dialog" aria-labelledby="testimonialCreateModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content recruiter-view-modal">
                            <div className="modal-header recruiter-view-modal-header">
                                <h5 className="modal-title recruiter-view-modal-title client" id="testimonialCreateModalLabel">
                                    Create new testimonial
                                </h5>
                                <a href='#' type="button" className="close recruiter-view-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i class="bi bi-x close-icon"></i></span>
                                </a>
                            </div>
                            <form>
                                <div className="modal-body">
                                    <div className="card p-4 recruiter-view-card">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="dash-form-group">
                                                    <label htmlFor="name" className='dash-form-label'>Name<span className='form-required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        aria-describedby="name"
                                                        name="name"
                                                        value={testimonial.name}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the name"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="dash-form-group">
                                                    <label htmlFor="position" className='dash-form-label'>Position<span className='form-required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="position"
                                                        aria-describedby="position"
                                                        name="position"
                                                        value={testimonial.position}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the position"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="viewType" className='dash-form-label'>Testimonial View Type<span className='form-required'>*</span></label>
                                                    <i class="bi bi-chevron-down toggle-icon"></i>
                                                    <select
                                                        id="viewType"
                                                        name="viewType"
                                                        value={testimonial.viewType}
                                                        onChange={handleInputChange}
                                                        className='form-control dash-form-input select-input'
                                                        required>
                                                        <option value="" disabled selected>-- Select testimonial view type --</option>
                                                        <option value="Client">Client</option>
                                                        <option value="Talent">Talent</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="audio" className='dash-form-label'>Upload Photo</label>
                                                    &nbsp;<small className='text-info'>Acceptable file format : .jpeg,.jpg,.png | </small>
                                                    <small className='text-info'>Acceptable file Size : 2MB</small>
                                                    <input
                                                        type="file"
                                                        id="audio"
                                                        accept='.jpeg,.jpg,.png'
                                                        aria-describedby="audio"
                                                        name="audio"
                                                        onChange={handleFileChange}
                                                        className='form-control dash-form-input is-file p-0'
                                                    />
                                                    {fileError &&
                                                        <div>
                                                            <small className='form-required'>{fileError}</small>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="review" className='dash-form-label'>Review<span className='form-required'>*</span></label>
                                                    <textarea
                                                        name="content"
                                                        value={testimonial.content}
                                                        onChange={handleInputChange}
                                                        id="review"
                                                        aria-describedby="review"
                                                        placeholder="Enter the review"
                                                        className='form-control dash-form-input'
                                                        required>
                                                    </textarea>
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer recruiter-view-modal-footer bg-whitesmoke br">
                                    {!editStatus && <button className="btn save-btn" type='submit'
                                        onClick={handleSubmit}>
                                        Save
                                    </button>}
                                    {editStatus && <button className="btn save-btn" type='submit'
                                        onClick={(e) => handleUpdate(e, testimonial._id)}>
                                        Update
                                    </button>}
                                    <button type="button" className="btn close-modal-btn" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*  */}

                <Footer />
            </div >
        </div >
    )
}

export default TestimonialsText