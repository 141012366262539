import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import ATSLayout from '../../components/ATSLayout';
import Footer from '../../components/Footer';
import './CreateCandidate.css';
import './CreateCandidate-responsive.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import 'react-datepicker/dist/react-datepicker.css';
import AuthContext from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const CreateClient = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};
    const [client, setClient] = useState();
    const { getProtectedData } = useContext(AuthContext);
    const [staffToken, setStaffToken] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [industryArray, setIndustryArray] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [searchIndustryInput, setSearchIndustryInput] = useState("");
    const [filteredIndustry, setFilteredIndustry] = useState([]);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [require, setRequire] = useState(false)

    const [IndustryAlert, setIndustryAlert] = useState("");

    const initialCredentials = {
        name: "",
        phone: "",
        email: "",
        companyName: "",
        count: "",
        text: "Client Created by Skillety",
    };
    const [credentials, setCredentials] = useState(initialCredentials);


    //for show success message for payment
    function showSuccessMessage(message) {
        Swal.fire({
            title: 'Success!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Alert',
            text: message,
            icon: 'info',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    useEffect(() => {
        if (id && staffToken) {
            axios.get(`${process.env.REACT_APP_BASE_API}/recruiter-client/${id}`, {
                headers: {
                    // Authorization: `Bearer ${staffToken}`,
                    Accept: 'application/json'
                }
            })
                .then(res => {
                    console.log(res.data)
                    setClient(res.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [id, staffToken])

    useEffect(() => {
        if (client) {
            console.log(client)
            setSelectedIndustry([client.industry])
            setCredentials({
                ...credentials,
                name: client.name,
                phone: client.phone,
                email: client.email,
                companyName: client.companyName,
                count: client.count,
                text: client.text,
            })
        }
    }, [client])

    useEffect(() => {
        setStaffToken(JSON.parse(localStorage.getItem('staffToken')))
    }, [staffToken])

    useEffect(() => {
        if (staffToken) {
            const fetchData = async () => {
                try {
                    const userData = await getProtectedData(staffToken);
                    console.log(userData);
                    setEmployeeId(userData.id || userData.uid);
                } catch (error) {
                    console.log(error)
                    navigate("/")
                }
            };

            fetchData();
        }
    }, [staffToken]);

    const registerUser = async (userData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/register-Client`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = response.data;

            if (!result.error) {
                console.log(result);
                await new Promise(() => {
                    Swal.fire({
                        title: 'Congratulations!',
                        text: 'Email has sent to created client.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        setCredentials(initialCredentials);
                        setSelectedIndustry([]);
                    });
                });
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateClient = async (userData) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BASE_API}/update-Client/${id}`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = response.data;

            if (!result.error) {
                console.log(result);
                await new Promise(() => {
                    Swal.fire({
                        title: 'Updated!',
                        text: '',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        navigate("/created-clients")
                    });
                });
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAllIndustries = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/industries`);
            setIndustryArray(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));

    };

    const handlePhoneNumberChange = (phoneNumber) => {
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            ['phone']: phoneNumber,
        }));
        if (phoneNumber) {
            phoneNumber = phoneNumber.toString();
            const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
            setIsPhoneValid(parsedPhoneNumber ? parsedPhoneNumber.isValid() : false);
        } else {
            setIsPhoneValid(false);
        }
    };

    const handleIndustrySearch = (e) => {
        setIndustryAlert("")
        const inputValue = e.target.value;
        setSearchIndustryInput(inputValue);
        if (inputValue.length > 0) {
            const clientIndustry = industryArray.filter((obj) => {
                return obj.industry.toLowerCase().includes(inputValue.toLowerCase());
            });
            if (clientIndustry.length > 0) {
                setFilteredIndustry(clientIndustry);
            }
        } else {
            setFilteredIndustry([]);
        }
    }

    const handleIndustryClick = (industry) => {
        setSelectedIndustry([industry]);
        setSearchIndustryInput("");
        setFilteredIndustry([]);
    }

    const handleDeselectIndustry = (industry) => {
        setSelectedIndustry(selectedIndustry.filter(selectIndustry => selectIndustry !== industry));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isPhoneValid) {
            if (credentials.name === "" || credentials.phone === "" || credentials.email === "" || !(emailRegex.test(credentials.email)) || selectedIndustry.length === 0 || credentials.companyName === "" || credentials.count === "") {
                setRequire(true)
            } else {
                const updatedCredentials = {
                    ...credentials,
                    industry: selectedIndustry[0],
                    recruiterId: employeeId,
                };
                console.log(updatedCredentials);
                registerUser(updatedCredentials);
            }
        }
    };

    const handleUpdate = (event) => {
        event.preventDefault();
        if (isPhoneValid) {
            if (credentials.name === "" || credentials.phone === "" || credentials.email === "" || !(emailRegex.test(credentials.email)) || selectedIndustry.length === 0 || credentials.companyName === "" || credentials.count === "") {
                setRequire(true)
            } else {
                const updatedCredentials = {
                    ...credentials,
                    industry: selectedIndustry[0],
                };
                console.log(updatedCredentials);
                updateClient(updatedCredentials);
            }
        }
    };

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                userProject: 'skillity-admin',
                page: `create-client`
            }
        });
    }, []);


    return (
        <div>
            <div class="main-wrapper main-wrapper-1">
                <div class="navbar-bg"></div>
                <ATSLayout />

                <div class="main-content">
                    <section class="section">
                        <div className="post-job-section">
                            <div className="admin-component-name">
                                Create Client
                            </div>
                            <div className="card post-job-card">
                                <div className="post-job-title">Create New Client </div>
                                {/* <div className="post-job-sub-title">Begin from scratch</div> */}


                                <div className="job-post-form-area pt-3">
                                    <form action="" onSubmit={handleSubmit}>

                                        <div className="row mt-4">
                                            <div className="col-12 col-md-6 col-margin-bottom">
                                                <div className="job-post-form-group">
                                                    <label htmlFor="first_name" className='job-post-form-label'>Full Name<span className='form-required'>*</span></label>
                                                    <input type="text"
                                                        className='job-post-form-input'
                                                        id='first_name'
                                                        name="name"
                                                        value={credentials.name}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter your first name"
                                                        required />
                                                </div>
                                                {require && <small className='text-danger text-capitalized form-error-message'>{credentials.name === "" && "required"}</small>}
                                            </div>

                                            <div className="col-12 col-md-6 col-margin-bottom">
                                                <div className="job-post-form-group">
                                                    <label htmlFor="mobile_number" className='job-post-form-label'>Mobile Number<span className='form-required'>*</span></label>
                                                    <PhoneInput
                                                        placeholder="Enter your mobile number"
                                                        value={credentials.phone ? credentials.phone.toString() : ''}
                                                        defaultCountry="IN"
                                                        className='job-post-form-input-mobile'
                                                        required
                                                        name="phone"
                                                        id='mobile_number'
                                                        onChange={(value) => handlePhoneNumberChange(value)} />
                                                    {/* <input type="number"
                                                        className='job-post-form-input'
                                                        id='mobile_number'
                                                        name="phone"
                                                        value={credentials.phone}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter mobile number"
                                                        min="0"
                                                        required /> */}
                                                </div>
                                                {!isPhoneValid &&
                                                    <small className='text-danger text-capitalized'>Enter valid phone number</small>
                                                }
                                                {/* <div className='validation-msg pt-2'>This field is required.</div> */}
                                                {require && <small className='text-danger text-capitalized form-error-message'>{credentials.phone === "" && "required"}</small>}
                                            </div>

                                            <div className="col-12 col-md-6 col-margin-bottom">
                                                <div className="job-post-form-group">
                                                    <label htmlFor="email_id" className='job-post-form-label'>Email ID<span className='form-required'>*</span></label>
                                                    <input type="email"
                                                        className='job-post-form-input'
                                                        id='email_id'
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter e-mail id"
                                                        required />
                                                </div>
                                                <div className='text-capitalized form-error-message'>
                                                    {require && <small className='text-danger'>{credentials.email === "" && "required"}</small>}
                                                    {(credentials.email && !(emailRegex.test(credentials.email))) &&
                                                        <small className='text-danger text-capitalized'>{(credentials.email && !(emailRegex.test(credentials.email))) && "Enter valid email address"}</small>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-margin-bottom">
                                                <div className="job-post-form-group">
                                                    <label htmlFor="company" className='job-post-form-label'>Current Company<span className='form-required'>*</span></label>
                                                    <input type="text"
                                                        className='job-post-form-input'
                                                        id='company'
                                                        name="companyName"
                                                        value={credentials.companyName}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the current company"
                                                        required />
                                                </div>
                                                {require && <small className='text-danger text-capitalized form-error-message'>{credentials.companyName === "" && "required"}</small>}
                                            </div>

                                            <div className="col-12 col-md-6 col-margin-bottom">
                                                <div className="job-post-form-group">
                                                    <label htmlFor="college_name" className='job-post-form-label'>Head Count<span className='form-required'>*</span></label>
                                                    <input type="number"
                                                        className='job-post-form-input'
                                                        id='college_name' name="count"
                                                        value={credentials.count}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the head count"
                                                        required />
                                                </div>
                                                {require && <small className='text-danger text-capitalized'>{credentials.count === "" && "required"}</small>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="post-job-btn-area">
                                {id ? <button className='post-job-btn' onClick={handleUpdate}>Update</button> : <button className='post-job-btn' onClick={handleSubmit}>Create</button>}
                            </div>
                        </div>
                    </section>
                </div >
                <Footer />
            </div >
        </div >
    )

}

export default CreateClient