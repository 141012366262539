import React from 'react';

const NavigateTestimonials = () => {

    return (
        <div>
            <div className="my-app-section pb-0">
                <div className='customize-website-nav'>
                    <h6 className='customize-website-nav-title'>Testimonials</h6>
                    <div className='customize-website-nav-links'>
                        <a href="/testimonial/text" className={`btn cus-web-nav-link ${window.location.pathname === '/testimonial/text' ? 'active' : ''}`}><i class="bi bi-file-earmark-text-fill"></i>Text</a>
                        <a href="/testimonial/video" className={`btn cus-web-nav-link ${window.location.pathname === '/testimonial/video' ? 'active' : ''}`}><i class="bi bi-file-earmark-play-fill"></i>Video</a>
                        <a href="/testimonial/audio" className={`btn cus-web-nav-link ${window.location.pathname === '/testimonial/audio' ? 'active' : ''}`}><i class="bi bi-file-earmark-music-fill"></i>Audio</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavigateTestimonials