import React, { useState } from 'react';
import { useEffect } from 'react';
import ATSLayout from '../../atsComponents/ATSLayout';
import Footer from '../../components/Footer';
import $ from 'jquery';
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TagManager from 'react-gtm-module'

const AllCompanyStaffATS = () => {
    const [atsToken, setatsToken] = useState("");
    const [allATSstaffs, setallATSstaffs] = useState([]);
    const [selectedATSstaffViewDetail, setselectedATSstaffViewDetail] = useState();
    const [loading, setLoading] = useState(false);
    const initialCredentials = {
        name: "",
        email: "",
        phone: "",
        companyStaff: "",
        password: "",
    }
    const [credentials, setcredentials] = useState(initialCredentials);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [selectedRole, setSelectedRole] = useState('Super-Admin');

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };


    const [x, setX] = useState([0, 10]);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [pageValue, setPageValue] = useState(1);

    const [submitValue, setSubmitValue] = useState("Update");
    const [editingEmployeeId, setEditingEmployeeId] = useState("");

    useEffect(() => {
        setatsToken(JSON.parse(localStorage.getItem('atsToken')))
    }, [atsToken])

    const handleEditStaff = (detail) => {
        setcredentials({
            ...credentials,
            name: detail.name,
            email: detail.email,
            phone: detail.phone,
            companyStaff: detail.companyStaff,
        })
        setSelectedRole(detail.role);
        setEditingEmployeeId(detail.id);
    }

    //for show success message for payment
    function showSuccessMessage(message) {
        Swal.fire({
            title: 'Congratulations!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    const getallATSstaffs = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/all-ats-staffs`, {
                headers: {
                    Authorization: `Bearer ${atsToken}`,
                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setallATSstaffs(result);

                const totalPages = Math.ceil(result.length / 10);
                setTotalPages(totalPages);

            } else {
                console.log(result);
            }

            setLoading(false);
        } catch (err) {
            console.log(err);

            setLoading(false);
        }
    }

    useEffect(() => {
        if (atsToken) {
            getallATSstaffs();
        }

    }, [atsToken, page]);

    const handleViewRecruiterDetail = (id) => {
        const selectedATSstaff = allATSstaffs.find(staff => staff.id === id);
        setselectedATSstaffViewDetail(selectedATSstaff);
    }

    const handleRemove = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                axios.delete(`${process.env.REACT_APP_BASE_API}/delete-recruiter/${id}`, {
                    headers: {
                        Authorization: `Bearer ${atsToken}`,
                        Accept: 'application/json'
                    }
                })
                    .then(res => {
                        console.log(res.data)
                        showSuccessMessage(`${selectedRole} successfully removed from company!`);
                        getallATSstaffs();
                    })
                    .catch(err => {
                        console.log(err)
                        showErrorMessage();
                    })
            }
        });

        // if () {
        //     setPage(page - 1);
        //     setPageValue(pageValue - 1);
        // }
    }

    const createRecruiter = async (userData) => {
        let method = 'post'; // Default method
        let endpoint = null;

        if (submitValue === "Update") {
            method = 'patch'; // Update method is PATCH
            endpoint = "update-employee";
        } else if (submitValue === "Save") {
            endpoint = "recruiter-create";
        }

        try {
            const response = await axios({
                method: method,
                url: `${process.env.REACT_APP_BASE_API}/${endpoint}`,
                data: userData,
                headers: {
                    Authorization: `Bearer ${atsToken}`,
                    Accept: 'application/json'
                }
            });

            const result = response.data;

            if (!result.error) {
                console.log(result);
                if(submitValue === "Update"){
                    closeModal()
                }
                showSuccessMessage(submitValue === "Update" ? "Staff detail updated successfully!" : submitValue === "Save" ? "New company staff for ATS has been created successfully!" : null)
                setcredentials(initialCredentials);
                setSelectedRole("Super-Admin");
                getallATSstaffs();
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
            showErrorMessage(error.response.data.message);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setcredentials({ ...credentials, [name]: value });
    }

    const handleGeneratePassword = () => {
        axios.get(`${process.env.REACT_APP_BASE_API}/random-password`)
            .then(response => {
                setcredentials({ ...credentials, password: response.data });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (submitValue === "Save") {
            const id = uuidv4();
            const updatedCredentials = {
                ...credentials,
                id,
                role: selectedRole
            };
            console.log(updatedCredentials);
            createRecruiter(updatedCredentials);
        } else if (submitValue === "Update") {
            const updatedCredentials = {
                ...credentials,
                id: editingEmployeeId,
                role: selectedRole
            };
            console.log(updatedCredentials);
            createRecruiter(updatedCredentials);
        }
    }

    const handleChangePage = (event) => {
        setPageValue(parseInt(event.target.value));
    };

    const handlePageSubmit = (event) => {
        event.preventDefault();
        setPage(Math.min(Math.max(1, pageValue), totalPages));

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const renderPageNumbers = () => {
        const totalPages = Math.ceil(allATSstaffs.length / 10);
        const maxPagesToShow = 5;

        if (!totalPages) return null;

        const pages = [];
        let startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item custom-pagination-page-item ${page === i ? 'active' : ''}`}>
                    <button className="page-link custom-pagination-page-link"
                        onClick={() => {
                            setPage(i);
                            setPageValue(i);
                        }}
                    >{i}</button>
                </li>
            );
        }

        return pages;
    };

    // Function to open the modal
  const openModal = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    // Close the modal using its ID
    const modal = document.getElementById('staffCreateModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    }
  };

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        userProject: 'skillity-admin',
        page: `all-company-staff-ats`
      }
    });
  }, []);

    return (
        <div>
            <div class="main-wrapper main-wrapper-1">
                <div class="navbar-bg"></div>

                <ATSLayout />

                <div class="main-content">
                    <section class="section">
                        <div className="my-app-section">
                            <div className="admin-component-name">
                                Company Staffs
                            </div>

                            {loading ? (
                                <div className="table-skeleton-area">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-data-skeleton-area">
                                                <div className="custom-flex-area">
                                                    <div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={25} width={250} />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={15} width={120} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-scroll-area mt-4 skeleton-table">
                                                    <div className="table skeleton-table table-striped table-hover admin-lg-table">
                                                        <tr className="skeleton-table-row">
                                                            <th className='w-5'>
                                                                <Skeleton height={18} width={30} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-20'>
                                                                <Skeleton height={18} width={80} />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="admin-lg-table-section">
                                            <div className='admin-lg-table-area man-app'>
                                                <div className='man-app-title-area custom-flex-area'>
                                                    <div>
                                                        <div className="man-app-title">
                                                            Company Staffs Details
                                                        </div>
                                                        <div className="man-app-sub-title">
                                                            Total Staffs :&nbsp;
                                                            <span>{allATSstaffs.length}</span>
                                                        </div>
                                                    </div>
                                                    <div className="create-btn-area">
                                                        <button
                                                            className='btn creat-data-btn'
                                                            data-toggle="modal"
                                                            title='Create new staff...'
                                                            data-target="#staffCreateModal"
                                                            onClick={() => {
                                                                openModal();
                                                                setcredentials(initialCredentials);
                                                                setSelectedRole("Super-Admin");
                                                                setSubmitValue("Save");
                                                            }}
                                                        >
                                                            <i class="bi bi-person-plus-fill"></i>
                                                            <span>Create New</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                {allATSstaffs.length === 0 ?
                                                    <div className="no-data-created-area">
                                                        <div className='no-data-created'>
                                                            <img src="../assets/img/no-data/no-data-img.png" className='no-data-img' alt="" />
                                                            <div className='no-data-text'>No Staff Created Yet..!</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="table-responsive table-scroll-area">
                                                        <table className="table table-striped table-hover admin-lg-table">
                                                            <tr className='dash-table-row man-app'>
                                                                <th className='dash-table-head'>No.</th>
                                                                <th className='dash-table-head'>Full Name</th>
                                                                <th className='dash-table-head'>Email ID</th>
                                                                <th className='dash-table-head'>Role</th>
                                                                <th className='dash-table-head'>Staff Type</th>
                                                                <th className='dash-table-head text-left'>Action</th>
                                                            </tr>

                                                            {/* table data */}
                                                            {allATSstaffs.slice((page - 1) * 10, page * 10).map((recruiter, index) => {
                                                                return (
                                                                    <tr className='dash-table-row client' key={recruiter.id}>
                                                                        <td className='dash-table-data1'>{((index + 1) + ((page - 1) * 10))}.</td>
                                                                        <td className='dash-table-data1 text-capitalized'>
                                                                            {recruiter.name}
                                                                        </td>
                                                                        <td className='dash-table-data1'>
                                                                            <a href={`mailto:${recruiter.email}`}
                                                                                className='dash-table-data1 link is-link'>
                                                                                {recruiter.email}
                                                                            </a>
                                                                        </td>
                                                                        <td className='dash-table-data1'>
                                                                            {recruiter.role}
                                                                        </td>
                                                                        <td className='dash-table-data1'>
                                                                            {recruiter.companyStaff}
                                                                        </td>
                                                                        <td className='text-left'>
                                                                            <div className="action-btn-area">
                                                                                <button className='job-view-btn' data-toggle="modal" title='View staff details...' data-target="#staffViewModal" onClick={() => handleViewRecruiterDetail(recruiter.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                                                                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                                                                                        />
                                                                                    </svg>
                                                                                </button>

                                                                                <button className='job-delete-btn' data-toggle="modal" title='Delete staff data...' data-target="#contactMsgdeleteModal" onClick={() => handleRemove(recruiter.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                                    </svg>
                                                                                </button>

                                                                                <button className='job-edit-btn' title='Edit staff details...'
                                                                                    data-target="#staffCreateModal"
                                                                                    data-toggle="modal"
                                                                                    onClick={() => {
                                                                                        openModal();
                                                                                        handleEditStaff(recruiter)
                                                                                        setSubmitValue("Update")
                                                                                    }}
                                                                                >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </table>
                                                    </div>
                                                }
                                            </div>

                                            {/* <div className="table-pagination-area pt-3">
                                                <div className="pagination-btn-area">
                                                    {x[0] > 0 &&
                                                        <button className='pag-prev-btn' onClick={() => setX([x[0] - 10, x[1] - 10])}>
                                                            <i class="bi bi-chevron-left"></i>
                                                        </button>
                                                    }

                                                    <div className='pag-page'>
                                                        <span className='current-page'>{Math.ceil(x[0] / 10) + 1}</span>&nbsp;/&nbsp;
                                                        <span className='total-page'>{Math.ceil(allATSstaffs.length / 10)}</span>
                                                    </div>

                                                    {(allATSstaffs.slice(x[0], x[1]).length === 10 && allATSstaffs.length > x[1]) &&
                                                        <button className='pag-next-btn' onClick={() => setX([x[0] + 10, x[1] + 10])}>
                                                            <i class="bi bi-chevron-right"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div> */}


                                            {allATSstaffs.length > 0 &&
                                                <div className="table-pagination-area">
                                                    <nav aria-label="Page navigation example pt-5">
                                                        <ul className="pagination justify-content-start">
                                                            <li className={`page-item ${(page !== 1 && Math.ceil(allATSstaffs.length / 10) !== 1) ? "" : "disabled"}`}>
                                                                <button className="page-link custom-pagination-page-link"
                                                                    onClick={() => {
                                                                        setPage(page - 1);
                                                                        setPageValue(page - 1);
                                                                    }}
                                                                >Previous</button>
                                                            </li>

                                                            {renderPageNumbers()}

                                                            <li className={`page-item ${(page !== Math.ceil(allATSstaffs.length / 10)) ? "" : "disabled"}`}>
                                                                <button className="page-link custom-pagination-page-link"
                                                                    onClick={() => {
                                                                        setPage(page + 1);
                                                                        setPageValue(page + 1);
                                                                    }}
                                                                >Next</button>
                                                            </li>
                                                        </ul>
                                                    </nav>

                                                    <div className="custom-pagination-input-area">
                                                        <p>Page</p>
                                                        <form onSubmit={handlePageSubmit}>
                                                            <input
                                                                type="number"
                                                                className="form-control custom-pagination-input"
                                                                value={pageValue}
                                                                min={1}
                                                                max={Math.ceil(allATSstaffs.length / 10)}
                                                                onChange={handleChangePage}
                                                            />
                                                            <button type="submit" className="d-none">Submit</button>
                                                        </form>
                                                        <p>of {Math.ceil(allATSstaffs.length / 10)}</p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>

                {/* Comapny staff details view modal here */}
                <div className="modal fade" id="staffViewModal" tabindex="-1" role="dialog" aria-labelledby="staffViewModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content recruiter-view-modal">
                            <div className="modal-header recruiter-view-modal-header">
                                <h5 className="modal-title recruiter-view-modal-title client" id="staffViewModalLabel">
                                    Company Staff Details
                                </h5>
                                <a href='#' type="button" className="close recruiter-view-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i class="bi bi-x close-icon"></i></span>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="card p-4 recruiter-view-card">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Full Name</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head text-capitalized">{selectedATSstaffViewDetail?.name}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Mobile Number</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head">
                                                <a href={`tel:${selectedATSstaffViewDetail?.phone}`}
                                                    className='view-det-sub-head link is-link'>
                                                    {selectedATSstaffViewDetail?.phone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Email ID</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head">
                                                <a href={`mailto:${selectedATSstaffViewDetail?.email}`}
                                                    className='view-det-sub-head link is-link'>
                                                    {selectedATSstaffViewDetail?.email}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Role</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head text-capitalized">{selectedATSstaffViewDetail?.role}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Staff Type</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head text-capitalized">{selectedATSstaffViewDetail?.companyStaff}</div>
                                        </div>
                                    </div>
                                    {/* <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Industry</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head text-capitalized">{aClient?.industry}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">Headcount</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head">{aClient?.count}</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-head">From where did you learn about Skillety?</div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="view-det-sub-head text-capitalized">{aClient?.text}</div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="modal-footer recruiter-view-modal-footer bg-whitesmoke br">
                                <button type="button" className="btn close-modal-btn" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* Comapny staff details view modal here */}
                <div className={`modal fade${showModal ? ' show' : ''}`}  id="staffCreateModal" tabindex="-1" role="dialog" aria-labelledby="staffCreateModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content recruiter-view-modal">
                            <div className="modal-header recruiter-view-modal-header">
                                <h5 className="modal-title recruiter-view-modal-title client" id="staffCreateModalLabel">
                                    {submitValue === 'Update'?'Update Staff' : 'Create New Staff'}
                                </h5>
                                <a href='#' type="button" className="close recruiter-view-close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true"><i class="bi bi-x close-icon"></i></span>
                                </a>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="card p-4 recruiter-view-card">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="name" className='dash-form-label'>Staff Name<span className='form-required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="staff_name"
                                                        aria-describedby="staffName"
                                                        name="name"
                                                        value={credentials.name}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the staff name"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="dash-form-group">
                                                    <label htmlFor="email" className='dash-form-label'>Email Address<span className='form-required'>*</span></label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        aria-describedby="email"
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter Email ID"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="dash-form-group">
                                                    <label htmlFor="phone" className='dash-form-label'>Phone No.<span className='form-required'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="phone"
                                                        aria-describedby="mobileNo"
                                                        name="phone"
                                                        value={credentials.phone}
                                                        onChange={handleInputChange}
                                                        placeholder="0XXXX XXXX XXX"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="select-role-area">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                            <label htmlFor="staffRole" className='dash-form-label'>Select Role<span className='form-required'>*</span></label>
                                                            <div className="role-radio-select-area">
                                                                <label className="role-radio-button">
                                                                    <input
                                                                        type="radio"
                                                                        name="role-radio-option"
                                                                        id='SuperAdmin'
                                                                        value="Super-Admin"
                                                                        checked={selectedRole === 'Super-Admin'}
                                                                        onChange={handleRoleChange}
                                                                    />
                                                                    <span className="role-radio"></span>
                                                                    Super Admin
                                                                </label>

                                                                <label className="role-radio-button">
                                                                    <input
                                                                        type="radio"
                                                                        name="role-radio-option"
                                                                        id='Manager'
                                                                        value="Manager"
                                                                        checked={selectedRole === 'Manager'}
                                                                        onChange={handleRoleChange}
                                                                    />
                                                                    <span className="role-radio"></span>
                                                                    Manager
                                                                </label>

                                                                <label className="role-radio-button">
                                                                    <input
                                                                        type="radio"
                                                                        name="role-radio-option"
                                                                        id='Recruiter'
                                                                        value="Recruiter-ATS"
                                                                        checked={selectedRole === 'Recruiter-ATS'}
                                                                        onChange={handleRoleChange}
                                                                    />
                                                                    <span className="role-radio"></span>
                                                                    Recruiter
                                                                </label>

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                            <div className="dash-form-group mb-auto mb-md-2">
                                                                <label htmlFor="companyStaff" className='dash-form-label'>Staff Type<span className='form-required'>*</span></label>
                                                                <i class="bi bi-chevron-down toggle-icon"></i>
                                                                <select
                                                                    id="companyStaff"
                                                                    name="companyStaff"
                                                                    value={credentials.companyStaff}
                                                                    onChange={handleInputChange}
                                                                    className='form-control dash-form-input select-input'
                                                                    required>
                                                                    <option value="" disabled selected>-- Select type of company staff --</option>

                                                                    {selectedRole === 'Super-Admin' && (
                                                                        <>
                                                                            <option value="CEO">CEO (Chief Executive Officer)</option>
                                                                            <option value="COO">COO (Chief Operating Officer)</option>
                                                                            <option value="HR">HR (Human Resources)</option>
                                                                        </>
                                                                    )}

                                                                    {selectedRole === 'Manager' && (
                                                                        <>
                                                                            <option value="VP">VP (Vice President)</option>
                                                                            <option value="AM">AM (Account Manager)</option>
                                                                            <option value="TL">TL (Team Leader)</option>
                                                                        </>
                                                                    )}

                                                                    {selectedRole === 'Recruiter-ATS' && (
                                                                        <>
                                                                            <option value="SeniorRecruiter">Senior Recruiter</option>
                                                                            <option value="Recruiter">Recruiter</option>
                                                                        </>
                                                                    )}


                                                                    {/* <option value="Operator">Operator</option>
                                                                    <option value="Finance">Finance</option>
                                                                    <option value="Customer support executive">Customer support executive</option>
                                                                    <option value="digitalmarketing team">digitalmarketing team</option>
                                                                    <option value="RMG">RMG</option> */}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="password" className='dash-form-label'>{submitValue === 'Update'?'Re-generate Password':'Generate Password'}{submitValue === "Save" && <span className='form-required'>*</span>}</label>
                                                    <div className='row'>
                                                        <div className="col-12 col-sm-12 col-lg-6">
                                                            <input
                                                                type={showPassword ? 'text' : 'password'}
                                                                id="password"
                                                                aria-describedby="password"
                                                                name="password"
                                                                value={credentials.password}
                                                                onChange={handleInputChange}
                                                                placeholder="Company staff password"
                                                                className='form-control dash-form-input'
                                                                required={submitValue === "Save"?true:false}
                                                            />
                                                            {/* {credentials.password ? */}
                                                            <i className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'} password-view-icon`}
                                                                onClick={handleTogglePassword}
                                                                id='togglePassword'>
                                                            </i>
                                                            {/* : null} */}
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-lg-6 pl-lg-0 mt-3 mt-lg-0 generate-btn-area">
                                                            <button
                                                                type="button"
                                                                className="btn generate-btn"
                                                                title='Generate Password'
                                                                onClick={handleGeneratePassword}
                                                            >
                                                                {submitValue === 'Update'?'Regenerate':'Generate'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer recruiter-view-modal-footer bg-whitesmoke br">
                                    {submitValue === "Save" && <button className="btn save-btn" type='submit'>
                                        Save
                                    </button>}
                                    {submitValue === "Update" && <button className="btn save-btn" type='submit'>
                                        Update
                                    </button>}
                                    <button type="button" className="btn close-modal-btn" data-dismiss="modal" onClick={closeModal}>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*  */}

                <Footer />
            </div >
        </div >
    )
}

export default AllCompanyStaffATS