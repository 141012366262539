import React from 'react';

const NavigateMedia = () => {

    return (
        <div>
            <div className="my-app-section pb-0">
                <div className='customize-website-nav'>
                    <h6 className='customize-website-nav-title'>Media Posting</h6>
                    <div className='customize-website-nav-links'>
                        <a href="/media-posting/event" className={`btn cus-web-nav-link ${window.location.pathname === '/media-posting/event' ? 'active' : ''}`}><i class="bi bi-calendar2-event-fill"></i>Event</a>
                        <a href="/media-posting/blog" className={`btn cus-web-nav-link ${window.location.pathname === '/media-posting/blog' ? 'active' : ''}`}><i class="bi bi-substack"></i>Blog</a>
                        <a href="/media-posting/video" className={`btn cus-web-nav-link ${window.location.pathname === '/media-posting/video' ? 'active' : ''}`}><i class="bi bi-camera-video-fill"></i>Video</a>
                        <a href="/media-posting/podcast" className={`btn cus-web-nav-link ${window.location.pathname === '/media-posting/podcast' ? 'active' : ''}`}><i class="bi bi-mic-fill"></i>Podcast</a>
                        <a href="/media-posting/news" className={`btn cus-web-nav-link ${window.location.pathname === '/media-posting/news' ? 'active' : ''}`}><i class="bi bi-newspaper"></i>News</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavigateMedia