import React, { useState, useEffect } from 'react';
import ATSLayout from '../../components/ATSLayout';
import Footer from '../../components/Footer';
import './FAQList.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from 'axios';
import TagManager from 'react-gtm-module'
import { useNavigate, useLocation } from 'react-router-dom';

const FAQList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const staffToken = JSON.parse(localStorage.getItem('staffToken'));
    const [isPageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState({});
    const [categories, setCategories] = useState({});
    const [page, setPage] = useState(1);
    const [pageValue, setPageValue] = useState(1);
    const faqListValues = {
        title: null,
        value: null,
        categoryId: null
    }
    const [faqLists, setFaqLists] = useState(faqListValues);
    const [require, setRequire] = useState(false);

    // Retrieve the 'page' query parameter
    const query = new URLSearchParams(location.search);
    const pageNumber = query.get('page');

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    //for show success message for payment
    function showSuccessMessage(message) {
        Swal.fire({
            title: 'Success!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFaqLists({ ...faqLists, [name]: value });
    };


    const getAllFAQCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/faq/get-all-faq-category-dropdown`, {
                headers: {
                    "Authorization": "Bearer " + staffToken
                }
            })
            setCategories(response.data.data)

        } catch (error) {
            console.log(error);
        }
    }

    const getAllFAQList = async (page) => {
        setPageLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/faq/get-all-faq-list?page=${page}`, {
                headers: {
                    "Authorization": "Bearer " + staffToken
                }
            })
            setList(response.data)

        } catch (error) {
            console.log(error);
            showErrorMessage(error.response.data.error);
        } finally {
            setPageLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setRequire(false);

            if (faqLists.title && faqLists.value && faqLists.categoryId) {
                let respond = await axios.patch(`${process.env.REACT_APP_BASE_API}/api/faq/create-faq-list`, {
                    title: faqLists.title,
                    value: faqLists.value,
                    categoryId: faqLists.categoryId,
                }, {
                    headers: {
                        "Authorization": "Bearer " + staffToken
                    }
                })
                showSuccessMessage(respond.data.message);
                window.location.reload();

            } else {
                setRequire(true);
            }

        } catch (error) {
            console.log(error);
            showErrorMessage(error.response.data.error);
        }
    }

    const handleUpdate = async (event, id) => {

        try {
            event.preventDefault();
            setRequire(false);

            if (faqLists.title && faqLists.value && faqLists.categoryId) {
                let respond = await axios.patch(`${process.env.REACT_APP_BASE_API}/api/faq/update-faq-list`, {
                    id: id,
                    title: faqLists.title,
                    value: faqLists.value,
                    categoryId: faqLists.categoryId,
                }, {
                    headers: {
                        "Authorization": "Bearer " + staffToken
                    }
                })
                showSuccessMessage(respond.data.message);
                window.location.reload();

            } else {
                setRequire(true);
            }

        } catch (error) {
            console.log(error);
            showErrorMessage(error.response.data.error);
        }

    }

    const handleDeleteFaqList = (id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete faq item!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let respond = await axios.delete(`${process.env.REACT_APP_BASE_API}/api/faq/delete-faq-list/${id}`, {
                        headers: {
                            "Authorization": "Bearer " + staffToken
                        }
                    })
                    showSuccessMessage(respond.data.message);
                    window.location.reload();
                } catch (error) {
                    console.log(error);
                    showErrorMessage(error.response.data.error);
                }
            }

        });
    }

    const handleShowHideFaqList = async (id) => {
        try {

            let respond = await axios.patch(`${process.env.REACT_APP_BASE_API}/api/faq/show-hide-faq-list`, {
                id: id
            }, {
                headers: {
                    "Authorization": "Bearer " + staffToken
                }
            })
            showSuccessMessage(respond.data.message);
            window.location.reload();

        } catch (error) {
            console.log(error);
            showErrorMessage(error.response.data.error);
        }
    }

    const changeURLParams = (value) => {
        navigate(`/faq-list?page=${parseInt(value)}`);
    };

    const handlePageSubmit = (event) => {
        event.preventDefault();
        const totalPages = list?.totalPages || 1;
        const newPage = Math.min(Math.max(1, pageValue), totalPages);
        setPage(newPage);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleChangePage = (event) => {
        setPageValue(parseInt(event.target.value));
        changeURLParams(event.target.value);
    };

    const changePageClick = (value) => {
        changeURLParams(value);
        setPage(value);
        setPageValue(value);

    };

    const renderPageNumbers = () => {
        const totalPages = list?.totalPages;
        const currentPage = list?.currentPage;
        const maxPagesToShow = 5;

        if (!totalPages) return null;

        const pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item custom-pagination-page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link custom-pagination-page-link"
                        onClick={() => {
                            setPage(i);
                            setPageValue(i);
                            changeURLParams(i);
                        }}
                    >{i}</button>
                </li>
            );
        }

        return pages;
    };

    useEffect(() => {
        if (pageNumber) {
            setPage(pageNumber);
            setPageValue(pageNumber);
        }
    }, [pageNumber])

    useEffect(() => {
        if (staffToken) {
            getAllFAQCategories();
            getAllFAQList(page);
        }
    }, [staffToken, page]);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                userProject: 'skillity-admin',
                page: `faq-list`
            }
        });
    }, []);
    return (
        <div>
            <div class="main-wrapper main-wrapper-1">
                <div class="navbar-bg"></div>
                <ATSLayout />
                <div class="main-content">
                    <section class="section">
                        <div className="my-app-section pt-0">
                            {isPageLoading ? (
                                <div className="table-skeleton-area">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-data-skeleton-area">
                                                <div className="custom-flex-area">
                                                    <div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={25} width={250} />
                                                        </div>
                                                        <div className='pt-3'>
                                                            <Skeleton height={15} width={120} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-scroll-area mt-4 skeleton-table">
                                                    <div className="table skeleton-table table-striped table-hover admin-lg-table">
                                                        <tr className="skeleton-table-row">
                                                            <th className='w-5'>
                                                                <Skeleton height={18} width={30} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-25'>
                                                                <Skeleton height={18} width={100} />
                                                            </th>
                                                            <th className='w-20'>
                                                                <Skeleton height={18} width={80} />
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Skeleton height={18} width={30} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={100} />
                                                            </td>
                                                            <td>
                                                                <Skeleton height={18} width={80} />
                                                            </td>
                                                        </tr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="admin-lg-table-section">
                                            <div className='admin-lg-table-area man-app'>
                                                <div className='man-app-title-area custom-flex-area'>
                                                    <div>
                                                        <div className="man-app-title">
                                                            FAQ List
                                                        </div>
                                                        {list && <div className="man-app-sub-title">
                                                            Total Records :&nbsp;
                                                            <span>{list?.totalCategories}</span>
                                                        </div>}
                                                    </div>
                                                    <div className="create-btn-area">
                                                        <button
                                                            className='btn creat-data-btn'
                                                            data-toggle="modal"
                                                            title='Create new list'
                                                            data-target="#listCreateModal"
                                                            onClick={() => {
                                                                setFaqLists(faqListValues);
                                                                setRequire(false)
                                                            }}
                                                        >
                                                            <i class="bi bi-person-plus-fill"></i>
                                                            <span>Create New</span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-scroll-area">
                                                    <table className="table table-striped table-hover admin-lg-table">
                                                        <tr className='dash-table-row man-app'>
                                                            <th className='dash-table-head'>No.</th>
                                                            <th className='dash-table-head'>Title</th>
                                                            <th className='dash-table-head'>Answer</th>
                                                            <th className='dash-table-head'>Category</th>
                                                            <th className='dash-table-head'>View Type</th>
                                                            <th className='dash-table-head text-left'>Action</th>
                                                        </tr>

                                                        {/* table data */}
                                                        {list?.data?.map((item, index) => {
                                                            return (
                                                                <tr className='dash-table-row client'
                                                                    key={item._id}>
                                                                    <td className='dash-table-data1'>{((index + 1) + ((page - 1) * 10))}..</td>
                                                                    <td className='dash-table-data1 text-capitalized'>
                                                                        {item.title}
                                                                    </td>
                                                                    <td className='dash-table-data1'>
                                                                        {item.value}
                                                                    </td>
                                                                    <td className='dash-table-data1'>
                                                                        {item.categoryId.name}
                                                                    </td>
                                                                    <td className='dash-table-data1'>
                                                                        {item.categoryId.viewType}
                                                                    </td>
                                                                    <td className='text-left'>
                                                                        <div className="action-btn-area">
                                                                            <button className='job-edit-btn' title='Edit item data...' data-toggle="modal" data-target="#listEditModal"
                                                                                onClick={() => {
                                                                                    setFaqLists({
                                                                                        _id:item._id,
                                                                                        categoryId:item.categoryId._id,
                                                                                        title:item.title,
                                                                                        value:item.value
                                                                                    });
                                                                                }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                                </svg>
                                                                            </button>

                                                                            <button className='job-delete-btn' data-toggle="modal" title='Delete item data...' data-target="#contactMsgdeleteModal"
                                                                                onClick={() => handleDeleteFaqList(item._id)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                                </svg>
                                                                            </button>

                                                                            <button className={item.status ? 'status-change-btn deactive' : 'status-change-btn active'} title={item.status ? 'Hide' : "Show"} onClick={() => handleShowHideFaqList(item._id)}>
                                                                                {item.status ? "Hide" : "Show"}
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                </div>
                                            </div>

                                            {list &&
                                                <div className="table-pagination-area">
                                                    <nav aria-label="Page navigation example pt-5">
                                                        <ul class="pagination justify-content-start">
                                                            <li className={`page-item ${(list?.currentPage !== 1 && list?.totalPages !== 1) ? "" : "disabled"} `}>
                                                                <button className="page-link custom-pagination-page-link"
                                                                    onClick={() => {
                                                                        changePageClick(parseInt(page) - 1);
                                                                    }}
                                                                >Previous</button>
                                                            </li>

                                                            {renderPageNumbers()}

                                                            <li className={`page-item ${(list?.currentPage !== list?.totalPages) ? "" : "disabled"}`}>
                                                                <button className="page-link custom-pagination-page-link"
                                                                    onClick={() => {
                                                                        changePageClick(parseInt(page) + 1);
                                                                    }}
                                                                >Next</button>
                                                            </li>
                                                        </ul>
                                                    </nav>

                                                    <div className="custom-pagination-input-area">
                                                        <p>Page</p>
                                                        <form onSubmit={handlePageSubmit}>
                                                            <input
                                                                type="number"
                                                                className="form-control custom-pagination-input"
                                                                value={pageValue}
                                                                min={1}
                                                                max={list?.totalPages || 1}
                                                                onChange={handleChangePage}
                                                            />
                                                            <button type="submit" className="d-none">Submit</button>
                                                        </form>
                                                        <p>of {list?.totalPages}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>

                {/*  create modal here */}
                <div className="modal fade" id="listCreateModal" tabindex="-1" role="dialog" aria-labelledby="listCreateModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content recruiter-view-modal">
                            <div className="modal-header recruiter-view-modal-header">
                                <h5 className="modal-title recruiter-view-modal-title client" id="listCreateModalLabel">
                                    Create new FAQ List
                                </h5>
                                <a href='#' type="button" className="close recruiter-view-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i class="bi bi-x close-icon"></i></span>
                                </a>
                            </div>
                            <form>
                                <div className="modal-body">
                                    <div className="card p-4 recruiter-view-card">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="title" className='dash-form-label'>Title<span className='form-required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="title"
                                                        aria-describedby="title"
                                                        name="title"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the title"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="value" className='dash-form-label'>Answer<span className='form-required'>*</span></label>
                                                    <textarea 
                                                        type="text"
                                                        id="value"
                                                        rows="4"
                                                        aria-describedby="value"
                                                        name="value"
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the answer"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="categoryId" className='dash-form-label'>FAQ Category<span className='form-required'>*</span></label>
                                                    <i class="bi bi-chevron-down toggle-icon"></i>
                                                    <select
                                                        id="categoryId"
                                                        name="categoryId"
                                                        onChange={handleInputChange}
                                                        className='form-control dash-form-input select-input'
                                                        required>
                                                        <option value="" disabled selected>-- Select view type --</option>
                                                        {categories && categories.length > 0 &&
                                                            categories.map((item, index) => (
                                                                <option value={item._id} key={index}>{item.name}&nbsp;-&nbsp;&#40;{item.viewType}&#41;</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer recruiter-view-modal-footer bg-whitesmoke br">
                                    <button className="btn save-btn" type='submit'
                                        onClick={handleSubmit}>
                                        Save
                                    </button>
                                    <button type="button" className="btn close-modal-btn" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/*  edit modal here */}
                <div className="modal fade" id="listEditModal" tabindex="-1" role="dialog" aria-labelledby="listEditModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content recruiter-view-modal">
                            <div className="modal-header recruiter-view-modal-header">
                                <h5 className="modal-title recruiter-view-modal-title client" id="listEditModalLabel">
                                    Update FAQ List
                                </h5>
                                <a href='#' type="button" className="close recruiter-view-close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i class="bi bi-x close-icon"></i></span>
                                </a>
                            </div>
                            <form>
                                <div className="modal-body">
                                    <div className="card p-4 recruiter-view-card">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="title" className='dash-form-label'>Title<span className='form-required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        id="title"
                                                        aria-describedby="title"
                                                        name="title"
                                                        value={faqLists.title}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the title"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="value" className='dash-form-label'>Answer<span className='form-required'>*</span></label>
                                                    <textarea
                                                        type="text"
                                                        id="value"
                                                        rows="4"
                                                        aria-describedby="value"
                                                        name="value"
                                                        value={faqLists.value}
                                                        onChange={handleInputChange}
                                                        placeholder="Enter the answer"
                                                        className='form-control dash-form-input'
                                                        required
                                                    />
                                                    {require && <div>
                                                        <small className='form-required'>This field is reqired</small>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="dash-form-group">
                                                    <label htmlFor="categoryId" className='dash-form-label'>FAQ Category<span className='form-required'>*</span></label>
                                                    <i class="bi bi-chevron-down toggle-icon"></i>
                                                    <select
                                                        id="categoryId"
                                                        name="categoryId"
                                                        onChange={handleInputChange}
                                                        className='form-control dash-form-input select-input'
                                                        value={faqLists.categoryId}
                                                        required>
                                                        <option value="" disabled selected>-- Select view type --</option>
                                                        {categories && categories.length > 0 &&
                                                            categories.map((item, index) => (
                                                                <option value={item._id} key={index}>{item.name}&nbsp;-&nbsp;&#40;{item.viewType}&#41;</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer recruiter-view-modal-footer bg-whitesmoke br">
                                    <button className="btn save-btn" type='submit'
                                        onClick={(e) => handleUpdate(e, faqLists._id)}>
                                        Update
                                    </button>
                                    <button type="button" className="btn close-modal-btn" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*  */}

                <Footer />
            </div >
        </div >
    )
}

export default FAQList